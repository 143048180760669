// default colors - start
// ==================================================
$body-font: 'Roboto', sans-serif;
$heading-font: 'Roboto', sans-serif;
// default colors - end
// ==================================================

// font weight - start
// ==================================================
$fw-bold: 700;
$fw-light: 300;
$fw-black: 900;
$fw-medium: 500;
$fw-regular: 400;
// font weight - end
// ==================================================

// default colors - start
// ==================================================
$default-pink: #f6716f;
$default-blue: #138afd;
$default-color: #535e72;
$default-black: #272d3a;
$default-gray: #808080;
// default colors - end
// ==================================================

// custom colors - start
// ==================================================
$pure-white: #ffffff;
$pure-black: #000000;

// $deep-gray: #e2e7fe;
$deep-gray: #e3e8fe;
$light-gray: #f7f9fe;
$rating-star: #f2b01e;

$twitter: #1da1f2;
$youtube: #ff0000;
$facebook: #3b5998;
$linkedin: #007bb5;
$pinterest: #bd081c;
$google-plus: #db4437;
$instagram-magenta: #c32aa3;

$gradient-purple: linear-gradient(to right, #814aff , #bb51ff);

// custom colors - end
// ==================================================



// transition - start
// ==================================================
$default-transition: all .3s ease-in-out;
// transition - end
// ==================================================