/* 2 - header section - start
==================================================================================================== */
.header-section {
  padding: 50px 0px;
  .container-fluid {
    max-width: 1750px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .brand-logo {
    .brand-link {
      width: 100%;
      display: block;
    }
  }

  /* main-menubar - start
	================================================== */
  .main-menubar {
    > ul {
      > li {
        padding: 14px 0px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0px;
        }
        > a {
          padding: 0px;
          font-size: 16px;
          position: relative;
          font-weight: $fw-medium;
          text-transform: capitalize;
        }
        &:hover {
          > a {
            color: $default-pink;
            &:after {
              color: $default-pink;
            }
          }
        }
      }
      > .active {
        > a {
          color: $default-pink;
          &:after {
            color: $default-pink;
          }
        }
      }
    }
  }
  /* main-menubar - end
	================================================== */

  /* btns-group - start
	================================================== */
  .btns-group {
    > ul {
      margin: -2px 0px;
      > li {
        .custom-btn {
          width: 100%;
          display: block;
          padding: 0px 38px;
        }
        .mobile-menu-btn {
          width: 55px;
          height: 55px;
          font-size: 26px;
          line-height: 60px;
          margin-left: 35px;
          color: $pure-white;
          text-align: center;
          border-radius: 100%;
          display: inline-block;
        }
      }
    }
  }
  /* btns-group - end
	================================================== */

  /* sub menu - start
	================================================== */
  .main-menubar {
    > ul {
      > .menu-item-has-child {
        position: relative;
        > a {
          padding-right: 15px;
          &:after {
            top: 5px;
            right: 0px;
            font-size: 10px;
            content: "\f100";
            position: absolute;
            font-weight: $fw-black;
            font-family: "Flaticon";
            transform: rotate(-180deg);
            transition: $default-transition;
          }
        }
        &:hover {
          > a {
            &:after {
              transform: rotate(45deg);
            }
          }
        }

        > .sub-menu {
          z-index: 2;
          top: 120%;
          opacity: 0;
          left: -30px;
          margin: 0px;
          min-width: 220px;
          padding: 20px 0px;
          visibility: hidden;
          position: absolute;
          border-radius: 5px;
          border-top-left-radius: 0px;
          background-color: $pure-white;
          transition: $default-transition;
          box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
          > li {
            width: 100%;
            display: table;
            padding-left: 20px;
            > a {
              width: 100%;
              height: 45px;
              padding: 0px 20px;
              line-height: 45px;
              position: relative;
              color: $default-color;
              &:after,
              &:before {
                top: 0px;
                left: 0px;
                bottom: 0px;
                width: 2px;
                content: "";
                height: 100%;
                position: absolute;
                transition: $default-transition;
              }
              &:before {
                background-color: $deep-gray;
              }
            }

            &:hover {
              > a {
                padding-left: 30px;
                color: $default-black;
                &:before {
                  background-color: $default-blue;
                }
              }
            }
          }
          > .active {
            > a {
              padding-left: 30px;
              color: $default-black;
              &:before {
                background-color: $default-blue;
              }
            }
          }
        }

        &:hover {
          > .sub-menu {
            top: 100%;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  /* sub menu - end
	================================================== */

  /* inner sub menu - start
	================================================== */
  .main-menubar {
    > ul {
      > .menu-item-has-child {
        > .sub-menu {
          > .menu-item-has-child {
            position: relative;
            > .sub-menu {
              z-index: 2;
              top: -20px;
              opacity: 0;
              left: 100%;
              margin: 0px;
              min-width: 220px;
              padding: 20px 0px;
              visibility: hidden;
              position: absolute;
              border-radius: 5px;
              border-top-left-radius: 0px;
              background-color: $pure-white;
              transition: $default-transition;
              box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.1);
              > li {
                width: 100%;
                display: table;
                padding-left: 20px;
                > a {
                  width: 100%;
                  height: 45px;
                  padding: 0px 20px;
                  line-height: 45px;
                  position: relative;
                  color: $default-color;
                  &:after,
                  &:before {
                    top: 0px;
                    left: 0px;
                    bottom: 0px;
                    width: 2px;
                    content: "";
                    height: 100%;
                    position: absolute;
                    transition: $default-transition;
                  }
                  &:before {
                    background-color: $deep-gray;
                  }
                }

                &:hover {
                  > a {
                    padding-left: 30px;
                    color: $default-black;
                    &:before {
                      background-color: $default-blue;
                    }
                  }
                }
              }
              > .active {
                > a {
                  padding-left: 30px;
                  color: $default-black;
                  &:before {
                    background-color: $default-blue;
                  }
                }
              }
            }

            &:hover {
              > .sub-menu {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  /* inner sub menu - end
	================================================== */
}

/* header changes in different pages - start
================================================== */
/* white-content - start */
.header-section.white-content {
  .main-menubar {
    > ul {
      > li {
        > a {
          color: $pure-white;
          &:after {
            color: $pure-white;
          }
        }

        &:hover {
          > a {
            color: $pure-white;
            &:after {
              color: $pure-white;
            }
          }
        }
      }
      .active {
        > a {
          color: $pure-white;
          &:after {
            color: $pure-white;
          }
        }
      }
    }
  }

  .btns-group {
    > ul {
      > li {
        .custom-btn {
          border: 1px solid $pure-white;
          background-color: rgba(255, 255, 255, 0.1);
          &:hover {
            border-color: $default-blue;
          }
        }
      }
    }
  }
}
/* white-content - end */

/* black-content - start */
.header-section.black-content {
  .main-menubar {
    > ul {
      > li {
        > a {
          color: $default-black;
        }

        &:hover {
          > a {
            color: $default-pink;
          }
        }
      }
      .active {
        > a {
          color: $default-pink;
        }
      }
    }
  }

  .btns-group {
    > ul {
      > li {
        .mobile-menu-btn {
          color: $default-black;
        }
      }
    }
  }
}
/* black-content - end */

/* header changes in different pages - end
================================================== */

/* sticky-header - start
================================================== */
.sticky-header {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  position: fixed;
  transition: $default-transition;
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    content: "";
    position: absolute;
    transition: $default-transition;
  }
}
.sticky-header.stuck {
  padding: 30px 0px;
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    background-color: $default-gray;
    box-shadow: 0 2px 6px 1px rgba(48, 48, 48, 0.1);
  }
}

.black-content.sticky-header.stuck {
  &:before {
    background-color: $pure-white;
  }
}
/* sticky-header - end
================================================== */

/* sidebar menu - start
================================================== */
.sidebar-menu-wrapper {
  display: block;

  .sidebar-menu {
    top: 0;
    right: -500px;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    min-width: 480px;
    max-width: 480px;
    padding: 40px 50px;
    overflow-y: scroll;
    background-color: $pure-white;
    transition: 0.6s cubic-bezier(1, 0, 0, 1);
    .mCustomScrollBox {
      overflow: visible !important;
    }
    .dismiss {
      .close-btn {
        width: 40px;
        height: 40px;
        font-size: 14px;
        cursor: pointer;
        line-height: 42px;
        text-align: center;
        color: $pure-white;
        border-radius: 3px;
        background-color: $default-blue;
      }
    }

    .sidebar-title {
      font-size: 21px;
      text-transform: capitalize;
    }

    /* btns-group - start */
    .btns-group {
      > ul {
        > li {
          margin-right: 10px;
          &:last-child {
            margin-right: 0px;
          }
          .store-btn {
            width: 100%;
            padding: 15px;
            display: block;
          }
        }
      }
    }
    /* btns-group - end */

    /* menu items - start */
    .menu-list {
      > li {
        width: 100%;
        display: table;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
        > a {
          font-size: 16px;
          color: $default-color;
          text-transform: capitalize;
        }
        &:hover {
          > a {
            color: $default-black;
            text-decoration: underline;
          }
        }
      }
      > .active {
        > a {
          color: $default-black;
          text-decoration: underline;
        }
      }
    }
    /* menu items - end */

    /* menu items >> sub menu - start */
    .menu-list {
      > .menu-item-has-child {
        .sub-menu {
          > li {
            padding-left: 30px;
            position: relative;
            &:before {
              top: 50%;
              left: 0px;
              width: 4px;
              height: 4px;
              z-index: 1;
              content: "";
              position: absolute;
              border-radius: 100%;
              transform: translateY(-50%);
              background-color: $deep-gray;
              transition: $default-transition;
            }
            > a {
              font-size: 14px;
              padding: 10px 0px;
              color: $default-color;
            }
            &:hover {
              &:before {
                background-color: $default-blue;
              }
              > a {
                color: $default-black;
              }
            }
          }
          > .active {
            &:before {
              background-color: $default-pink;
            }
            > a {
              color: $default-black;
            }
          }
        }
      }
    }
    /* menu items >> sub menu - end */

    /* contact-info - start */
    .contact-info {
      > ul {
        > li {
          font-size: 16px;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
          .icon {
            font-size: 18px;
            margin-right: 15px;
            color: $default-blue;
          }
        }
      }
    }
    /* contact-info - end */
  }

  .sidebar-menu.active {
    right: 0px;
    box-shadow: 0px 10px 55px 1px rgba(137, 173, 255, 0.35);
  }

  .overlay {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    display: none;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    transition: $default-transition;
  }
  .overlay.active {
    opacity: 1;
    display: block;
  }
}
.sidebar-menu::-webkit-scrollbar {
  width: 0px;
}

#previwnavbar {
  ul {
    li {
      &:hover {
        .nav-link {
          color: $pure-white;
        }
      }
    }
  }
}

/* sidebar menu - end
================================================== */

/* 2 - header section - end
==================================================================================================== */
